import React, { useState, useEffect } from "react";
import { Row, Col, Drawer } from "antd";
import SubAccountsListing from "../subAccount/SubAccountsListing";
import "../../assets/css/omessage.css"; // Ensure your CSS file is imported here
import SideBarMenuItem from "../../components/main/SideBarMenuItem";

export function TopMenuItems(props) {
  const { appProperties } = props;

  useEffect(() => {
    if (appProperties?.osyncId) {
      appProperties?.controller?.getUserData().then(function (userData) {
        appProperties.companyId = userData?.companyId;
        appProperties.userId = userData?.userId;
        appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then(function (queryParamsFromApp) {
          var urlParams = new URLSearchParams(queryParamsFromApp);
          urlParams.delete("sideBar");
          urlParams.delete("topBar");
        });
      });
    }
  }, [appProperties?.osyncId]);

  const [settingsOffCanvas, setSettingsOffCanvas] = useState(false);
  const showSettingsMenu = () => {
    setSettingsOffCanvas(true);
  };
  const smsSettingsClose = () => {
    setSettingsOffCanvas(false);
  };

  return (
    <>
      {appProperties?.serviceInstalled ? (
        <div className="d-flex align-items-center">
          <span className={props.class} onClick={showSettingsMenu}>
            <div className="actionIconsSprite sendSMSMoreMenuIcon" alt="menu" />
          </span>
        </div>
      ) : null}
      <Drawer
        className="removeOutlineAntDrawer"
        title={
          <Row className="">
            <Col span={8} onClick={smsSettingsClose}>
              <div className="d-flex justify-content-start">
                <div className="actionIconsSprite topBarArrow" alt="menu" />
              </div>
            </Col>
            <Col span={8}>
              <div className="d-flex justify-content-center">
                <div>Menu</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="d-flex justify-content-end">
                <div className="sprite-icon topBarHellosend" alt="menu" />
              </div>
            </Col>
          </Row>
        }
        placement="right"
        closable={false}
        onClose={smsSettingsClose}
        width={200}
        open={settingsOffCanvas}
      >
        <Row>
          <Col span={24} className="ms-1 ps-2">
            <SubAccountsListing
              closeOffCanvas={smsSettingsClose}
              selectComponentWidth={"103%"}
              selectComponentHeight={50}
              showSubHeader={true}
            />
          </Col>
        </Row>
        <SideBarMenuItem sideBarCommonHandling={true} />
      </Drawer>
    </>
  );
}
