import ZohoCRM from "./ZohoCRMController";

const ZohoCRMEmbedded = {
  init: function () {
    return new Promise((resolve, reject) => {
      console.log(":::::::::::: handleFetchTemplates dinku embeddedApp :::::::::: 1111 ----", ZohoCRM.selectedEntityModuleFromZohoCRM);
      window?.ZOHO?.embeddedApp.on("PageLoad", function (data) {
        ZohoCRM.selectedEntityModuleFromZohoCRM = data?.Entity;
        if (data?.module) {
          ZohoCRM.selectedEntityModuleFromZohoCRM = data?.module;
        }

        ZohoCRM.selectedEntityIdsFromZohoCRM = data?.EntityId;
        console.log(":::::::::::: handleFetchTemplates dinku embeddedApp :::::::::: 33333 ----", data);
        if (data?.EntityId) {
          ZohoCRM.serviceInitialized = true;
        }
        ZohoCRM.configData = data?.configdata?.parameters;
        resolve(data);
      });
      window?.ZOHO?.embeddedApp?.init().then(function () {
        console.log(":::::::::::: handleFetchTem plates dinku embeddedApp :::::::::: 2222 ----");
      });
    });
  },
  setWebhook: function (webhookUrl, webhookId, appProperties, integId) {
    return new Promise((resolve, reject) => {
      console.log("dtiou >>> inside >> webhookUrl", webhookUrl);
      console.log("dtiou >>> inside >> webhookId", webhookId);

      console.log("dtiou >>> inside >> integId", integId);
      var parameters = {
        selectedId: "${!" + appProperties.module + ".Id}",
        module: appProperties.module,
        eventId: webhookId,
        integId: integId,
      };
      var customActionValueToBeStored = {
        parameters: JSON.stringify(parameters),
      };

      console.log("dtiou >>> inside >> customActionValueToBeStored", customActionValueToBeStored);
      customActionValueToBeStored.url = webhookUrl;

      console.log("dtiou customActionValueToBeStored >>>>>>>>>>", JSON.stringify(customActionValueToBeStored));
      window.ZOHO.CRM.ACTION.setConfig(customActionValueToBeStored);
    });
  },
  getWebhook: function () {
    return new Promise((resolve, reject) => {
      console.log(":::::::::::: handleFetchTemplates dtiou ZohoCRM.configData :::::::::: 33333 ----", ZohoCRM.configData);
      resolve(ZohoCRM.configData);
    });
  },
  getUserData: function () {
    return new Promise((resolve, reject) => {
      window.ZOHO.CRM.CONFIG.getCurrentUser()
        .then(function (data) {
          window.ZOHO.CRM.CONFIG.getOrgInfo()
            .then(function (dataOrg) {
              var companyId = dataOrg.org[0].zgid;
              var emailId = data.users[0].email;
              var userId = data.users[0].zuid;
              var companyOrgId = companyId;

              var userData = {
                companyId: companyId,
                userId: userId,
                companyOrgId: companyOrgId,
                emailId: emailId,
              };
              resolve(userData);
            })
            .catch(function (err) {
              reject(err);
            });
        })
        .catch(function (err) {
          console.log("handleFetchTemplates getUserData catch >>>>>>>>>>", err);
        });
    }).catch(function (err) {
      console.log("handleFetchTemplates whole catch >>>>>>>>>>", err);
    });
  },
  getFilterObjectForShrinkView: function () {
    return new Promise((resolve, reject) => {
      resolve({});
    });
  },
};

export default ZohoCRMEmbedded;
