import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Dropdown, Layout, Row, Space, Badge } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { urlParams } from "./../../constants/AppConstants";
import { AppContext } from "./../../context/AppContext";

import useLicense from "../../hooks/useLicense.jsx";
import SubAccountsListing from "./../../pages/subAccount/SubAccountsListing.jsx";
import { useLocation } from "react-router-dom";

const { Header } = Layout;

const titles = {
  channels: "Channels",
  phone: "Add phone",
  templates: "Templates",
  inbox: "Inbox",
  module: "Campaign Modules",
  sendsms: "Send SMS",
  messages: "Bulk SMS",
  campaigns: "Overview",
  campaign: "Campaign",
  chat: "Chat",
  users: "Users",
  workflow: "Automation",
  campaignOverView: "Detailed Overview",
};

function Headerbar(props) {
  let topBarNeeded = urlParams.get("topBar");
  let sideBar = urlParams.get("sideBar");
  let path = window.location.pathname;
  if (path === "/zoho-crm/webhook") {
    sideBar = "false";
    topBarNeeded = "false";
  }
  var sideBarFalse = "block";
  if (path === "/zoho-crm/landing") {
    sideBarFalse = "d-none";
  }
  const [title, setTitle] = useState(props.headerTitle);
  const [appProperties] = useContext(AppContext);
  const [userLogo, setUserLogo] = useState();
  const [isTopBarNeeded] = useState(topBarNeeded === undefined || topBarNeeded !== "false");
  const [allPageContainer, setAllPageContainer] = useState("");
  const [isSideBar, setIsSideBar] = useState(true);
  const location = useLocation();
  const [loginAreaMenuItems, setLoginAreaMenuItems] = useState([]);
  const { fetchStripeURL } = useLicense();
  let onBoardingPage = urlParams.get("onBoardingPage");

  useEffect(() => {
    if (appProperties?.controller?.pricingLink) {
      console.log("appProperties?.controller?.pricingLink >>>>>>>>>", appProperties?.controller?.pricingLink);

      let loginAreaMenuItemList = [];
      if (appProperties?.licenseObj?.licenseDetails?.licenseStatus === 4 || appProperties?.licenseObj?.licenseDetails?.licenseStatus === 5) {
        appProperties.controller.pricingLink(appProperties).then(function (resp) {
          loginAreaMenuItemList.push({
            label: (
              <a style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer" href={resp}>
                Manage Subscription
              </a>
            ),
            key: "Manage_Subscription",
            icon: <UserOutlined />,
          });
        });
      } else {
        console.log("appProperties?.licenseHandledBy >>>>>>>>>", appProperties?.licenseHandledBy);
        if (appProperties?.licenseHandledBy === "STRIPE") {
          loginAreaMenuItemList.push({
            label: (
              <a style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer" onClick={handleManageSubscription}>
                Manage Subscription
              </a>
            ),
            key: "Manage_Subscription",
            icon: <UserOutlined />,
          });
        } else {
          loginAreaMenuItemList.push({
            label: "Manage Subscription",
            key: "Manage_Subscription",
            icon: <UserOutlined />,
            "data-cb-type": "portal",
          });
        }
      }
      setLoginAreaMenuItems(loginAreaMenuItemList);
    }
  }, [appProperties?.controller, appProperties?.licenseHandledBy]);

  useEffect(() => {
    if (appProperties?.headerTitleName) {
      setTitle(appProperties?.headerTitleName);
    }
  }, [appProperties?.headerTitleName]);

  useEffect(() => {
    if (sideBar === "false") {
      setAllPageContainer("container");
      setIsSideBar(false);
    }
  }, []);
  useEffect(() => {
    appProperties.headerTitleName = "";
    setTitle(titles[props.headerTitle] || props.headerTitle);
    let userLogoText = appProperties.userLogo;
    if (userLogoText === "") {
      userLogoText = appProperties.userName.charAt(0).toUpperCase();
    }
    setUserLogo(userLogoText);
  }, [props.headerTitle, appProperties.userLogo, appProperties.userName, location.pathname]);

  if (!isTopBarNeeded) {
    return <></>;
  }
  const handleManageSubscription = (e) => {
    console.log("appProperties.licenseHandledBy >>>>>>>>", appProperties.licenseHandledBy);
    if (appProperties.licenseHandledBy === "STRIPE") {
      fetchStripeURL(appProperties).then(function (stripeLink) {
        window.open(stripeLink.url);
      });
    }
  };

  const registerChargeBee = (e) => {
    console.log("appProperties.licenseHandledBy registerChargeBee>>>>>>>>", appProperties.licenseHandledBy);
    window.Chargebee?.registerAgain();
    console.log("click", e);
  };

  const menuProps = {
    items: loginAreaMenuItems,
    onMouseEnter: registerChargeBee,
    style: {
      marginTop: "25px",
    },
  };
  return (
    <Header
      className={`allPageContainer ${sideBarFalse}`}
      style={{
        padding: 0,
        position: sideBar === "false" ? "relative" : "fixed",
        width: sideBar === "false" ? "relative" : "85.4svw",
        height: 60,
        zIndex: 3,
        marginLeft: sideBar === "false" ? "0rem" : "12.6rem",
        lineHeight: "64px",
        backgroundColor: sideBar === "false" ? "transparent" : "#fff",
        borderLeft: "none",
        paddingLeft: "1rem",
      }}
    >
      <Row style={{ overflow: "hidden" }}>
        <Col span={12}>
          <h1
            className="ms-4 m-3"
            style={{
              textTransform: "capitalize",
              color: "#111827",
              fontWeight: 700,
              fontSize: 24,
            }}
          >
            {title}
          </h1>
        </Col>

        <Col span={4}>
          <SubAccountsListing selectComponentWidth={"90%"} selectComponentHeight={40} />
        </Col>

        {!onBoardingPage ? (
          <Col span={3} className="me-4">
            <Button
              data-sleek
              data-badge-changelog
              className="announcementHover ms-2"
              style={{ border: "none", boxShadow: "none", width: "-webkit-fill-available" }}
            >
              <div className="actionIconsSprite announcement">
                <span className="ms-4"> What's New</span>
              </div>
            </Button>
          </Col>
        ) : null}

        <Col span={3}>
          <Space className="d-flex justify-content-end h-100 w-100">
            {isSideBar ? (
              <>
                <Dropdown menu={menuProps} overlayClassName="profilePopup">
                  <Button className="ps-0 border-none boxShadowRemove  d-flex justify-content-center h-100 w-100">
                    <Space className="profileAvatarBg h-100 w-100">
                      <Avatar
                        style={{
                          backgroundColor: "#d5fce3",
                          color: "black",
                          verticalAlign: "middle",
                          border: "1px solid #d5fce3",
                        }}
                        size="large"
                        className="manageSubscription"
                        src={userLogo}
                        border="light"
                      >
                        {userLogo}
                      </Avatar>
                      <div className="actionIconsSprite fromNumberDropDownIcon" />
                    </Space>
                  </Button>
                </Dropdown>
              </>
            ) : null}
          </Space>
        </Col>
      </Row>
    </Header>
  );
}

export default Headerbar;
