import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Modal, Button, Drawer, Popover, Menu } from "antd";
import "../../assets/css/commonVessels.css";
import { PlusCircleFilled, ArrowLeftOutlined } from "@ant-design/icons";
import { AppContext } from "./../../context/AppContext";
import creditsCoin from "../../assets/images/creditsCoin.png";
import OAppSLogo from "../../assets/images/LogoOAppS.png";
import { RegisterChargebee } from "../utils/ChargebeeRegistration";
import useHttp from "../../hooks/useHttp";
import coin from "../../assets/images/coin.png";
import BalanceCredits from "../../components/main/BalanceCredits";
import { Chargebee_CHARGE_NAME, Chargebee_PLAN_NAME, urlParams } from "../../constants/AppConstants";
import { useLocation } from "react-router-dom";
export function FundsMonitor({ popOver }) {
  const { fetchData } = useHttp();
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  const [showChargeBee, setShowChargeBee] = useState(null);
  const [fundsLoading, setFundsLoading] = useState(false);
  const [balanceCredits, setBalanceCredits] = useState("0");
  const [chargeBeePlan, setChargeBeePlan] = useState(Chargebee_CHARGE_NAME);
  const [showOneTimeCharge, setShowOneTimeCharge] = useState(false);
  const [balanceBackground, setBalanceBackground] = useState("creditsCardClass");
  const location = useLocation();

  useEffect(() => {
    if (appProperties?.licenseObj?.licenseDetails) {
      let { creditsConsumed, creditsPurchased } = appProperties.licenseObj.licenseDetails;
      let balanceCredits = creditsPurchased - creditsConsumed;
      setBalanceCredits(Number.parseFloat(balanceCredits));
    }
  }, [
    appProperties.licenseObj?.licenseDetails,
    appProperties.licenseObj?.licenseDetails?.creditsConsumed,
    appProperties.licenseObj?.licenseDetails?.creditsPurchased,
  ]);

  function registerAgain() {
    RegisterChargebee("Omni", appProperties, setAppProperties);
  }
  const handlefundsAdd = () => {
    setFundsLoading(true);
    fetchData(
      "license/checkout/page?creditCount=50&planName=" +
        Chargebee_PLAN_NAME +
        "&chargeName=" +
        Chargebee_CHARGE_NAME +
        "&leftServiceId=" +
        appProperties.leftServiceId,
      "GET",
      null,
      appProperties
    )
      .then(function (response) {
        response = JSON.parse(response);
        setCheckoutUrl(response.data.checkoutUrl);
        setShowChargeBee(true);
        setFundsLoading(false);
      })
      .catch((error) => {
        setFundsLoading(false);
      });
  };
  function handleCreditBalance(creditObj) {
    let usagePercentage = creditObj.usagePercentage;

    if (usagePercentage === 100) {
    } else {
    }
  }

  function CreditsPopoverContent(props) {
    let balanceCredits = props.balanceCredits;
    let chargeBeePlan = props.chargeBeePlan;
    let registerAgain = props.registerAgain;
    const [appProperties, setAppProperties] = useContext(AppContext);

    return (
      <>
        <Row>
          <Col>
            <img src={coin} alt="coin" />
            <span className="ps-1">Available Funds : {balanceCredits}</span>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="text-muted">Top up your wallet with funds to purchase a phone number and enable SMS functionality.</Col>
        </Row>
        <Row className="d-flex justify-content-end">
          <Col>
            {appProperties?.licenseObj?.licenseDetails?.licenseStatus === 4 || appProperties?.licenseObj?.licenseDetails?.licenseStatus > 10 ? (
              <Button className="fundsUI" type="link" onClick={handlefundsAdd} loading={fundsLoading}>
                Buy Funds
              </Button>
            ) : (
              <Button
                data-cb-type="checkout"
                data-cb-item-0={chargeBeePlan}
                data-cb-item-0-quantity="1"
                type="link"
                onMouseEnter={registerAgain}
              >
                Buy Funds
              </Button>
            )}
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      {showChargeBee ? <ChargeebeeCart showChargeBee={showChargeBee} setShowChargeBee={setShowChargeBee} checkoutUrl={checkoutUrl} /> : null}

      {popOver === true ? (
        <Popover
          id="buyNumberPopover"
          placement="bottom"
          overlayClassName="wrapper-notify"
          zIndex={888}
          content={<CreditsPopoverContent balanceCredits={balanceCredits} chargeBeePlan={chargeBeePlan} registerAgain={registerAgain} />}
          trigger="click"
        >
          <Button shape="round" className="fundsUI">
            <Row>
              <BalanceCredits
                class={true}
                setBalanceBackground={setBalanceBackground}
                setShowOneTimeCharge={setShowOneTimeCharge}
                setChargeBeePlan={setChargeBeePlan}
                chargeBeePlan={chargeBeePlan}
                handleCreditBalance={handleCreditBalance}
                setBalanceCredits={setBalanceCredits}
              />
              <Col span={6}>
                <PlusCircleFilled
                  className="fs-5"
                  style={{
                    color: "#605BFF",
                    verticalAlign: "inherit",
                  }}
                />
              </Col>
            </Row>
          </Button>
        </Popover>
      ) : (
        <>
          {appProperties?.licenseObj?.licenseDetails?.licenseStatus === 4 ||
          location.pathname === "/sendsms" ||
          appProperties?.licenseObj?.licenseDetails?.licenseStatus > 10 ? (
            <Button
              className="fundsUI"
              shape="round"
              onClick={() => {
                if (location.pathname === "/sendsms") {
                  window.open("channels/textsms/helloSend?" + urlParams);
                } else {
                  handlefundsAdd();
                }
              }}
              loading={fundsLoading}
            >
              <Row>
                <BalanceCredits
                  class={true}
                  setBalanceBackground={setBalanceBackground}
                  setShowOneTimeCharge={setShowOneTimeCharge}
                  setChargeBeePlan={setChargeBeePlan}
                  chargeBeePlan={chargeBeePlan}
                  handleCreditBalance={handleCreditBalance}
                  // onMouseEnter={registerAgain}
                />
                <Col span={6}>
                  <PlusCircleFilled
                    className="fs-5"
                    style={{
                      color: "#605BFF",
                      verticalAlign: "inherit",
                    }}
                  />
                </Col>
              </Row>
            </Button>
          ) : (
            <Button
              className="fundsUI"
              data-cb-type="checkout"
              data-cb-item-0={chargeBeePlan}
              data-cb-item-0-quantity="1"
              shape="round"
              onMouseEnter={registerAgain}
            >
              <Row>
                <BalanceCredits
                  class={true}
                  setBalanceBackground={setBalanceBackground}
                  setShowOneTimeCharge={setShowOneTimeCharge}
                  setChargeBeePlan={setChargeBeePlan}
                  chargeBeePlan={chargeBeePlan}
                  handleCreditBalance={handleCreditBalance}
                  // onMouseEnter={registerAgain}
                />
                <Col span={6}>
                  <PlusCircleFilled
                    className="fs-5"
                    style={{
                      color: "#605BFF",
                      verticalAlign: "inherit",
                    }}
                  />
                </Col>
              </Row>
            </Button>
          )}
        </>
      )}
    </>
  );
}

export const NotEnoughCredits = (props) => {
  const { fetchData } = useHttp();
  let chargeBeePlan = props.chargeBeePlan;
  let chargeBeeCharge = props.chargeBeeCharge;
  let alreadyRegistered = props.alreadyRegistered;
  let setAlreadyRegistered = props.setAlreadyRegistered;
  let appProperties = props.appProperties;
  let setAppProperties = props.setAppProperties;
  // let setShowNoCredits = props.setShowNoCredits;
  // let showNoCredits = props.showNoCredits;
  const [showChargeBee, setShowChargeBee] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState(null);

  return (
    <>
      {showChargeBee ? <ChargeebeeCart showChargeBee={showChargeBee} setShowChargeBee={setShowChargeBee} checkoutUrl={checkoutUrl} /> : null}
      <Modal
        open={props.open}
        className="creditsModal"
        icon={creditsCoin}
        closable={false}
        onCancel={props.handleNoCreditsCancel}
        width={600}
        footer={null}
        centered
      >
        <Menu className="buyNumberMenu w-50" mode="horizontal" selectedKeys={["topup"]}>
          <Menu.Item className="buyNumberMenuItem ps-5 pe-5" key="topup" style={{ cursor: "text" }}>
            Top up
          </Menu.Item>
        </Menu>
        <div className="m-2">
          {appProperties?.licenseObj?.licenseDetails?.creditsPurchased > 0 ? (
            <div className="creditsModalTitle">Not enough funds</div>
          ) : (
            <div className="creditsModalTitle" style={{ color: "#334D6E" }}>
              Top up your wallet
            </div>
          )}

          <div className="creditsModalContent">
            {appProperties?.licenseObj?.licenseDetails?.creditsPurchased > 0
              ? "Top up your wallet with funds to purchase a phone number and enable SMS functionality."
              : "Add funds to your account to acquire a phone number and enable a seamless SMS channel. All your transactions will be shown explicitly."}
          </div>
        </div>

        <Row>
          <Col span={6}>
            <div className="d-flex align-items-center">
              <img className="mt-3" height={70} src={creditsCoin} alt="creditsCoin" />
            </div>
          </Col>
          <Col span={18} className="mt-auto">
            <div className="d-flex flex-row-reverse align-items-center gap-3">
              {appProperties?.licenseObj?.licenseDetails?.licenseStatus === 4 ||
              appProperties?.licenseObj?.licenseDetails?.licenseStatus > 10 ? (
                <Button
                  style={{
                    backgroundColor: "#605bff",
                    color: "#fff",
                    height: 40,
                    borderRadius: 12,
                    width: 140,
                  }}
                  type=""
                  loading={loading}
                  onClick={() => {
                    setLoading(true);
                    fetchData(
                      "license/checkout/page?creditCount=50&planName=" +
                        Chargebee_PLAN_NAME +
                        "&chargeName=" +
                        Chargebee_CHARGE_NAME +
                        "&leftServiceId=" +
                        appProperties.leftServiceId,
                      "GET",
                      null,
                      appProperties
                    ).then(function (response) {
                      response = JSON.parse(response);
                      if (response.data) {
                        setCheckoutUrl(response.data.checkoutUrl);
                        setLoading(false);
                        props.handleNoCreditsCancel();
                        setShowChargeBee(true);
                      }
                    });
                  }}
                >
                  Buy Funds
                </Button>
              ) : (
                <Button
                  data-cb-type="checkout"
                  data-cb-item-0={chargeBeePlan}
                  data-cb-item-0-quantity="1"
                  onMouseEnter={registerAgain}
                  type=""
                  className="buyCreditsButton ms-3"
                >
                  Buy Funds
                </Button>
              )}
              <Button style={{ color: "#605BFF", fontWeight: 600 }} className="buyNumberCancel" onClick={props.handleNoCreditsCancel}>
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );

  function registerAgain() {
    if (alreadyRegistered === "false") {
      setAlreadyRegistered("true");
      RegisterChargebee("Omni", appProperties, setAppProperties);
    }
  }
};

export const ChargeebeeCart = ({ showChargeBee, setShowChargeBee, checkoutUrl }) => {
  const [appProperties, setAppProperties] = useContext(AppContext);
  const { fetchData } = useHttp();

  const getLicenseDetails = () =>
    fetchData(`omni/license?leftServiceId=${appProperties.leftServiceId}&osyncId=${appProperties.osyncId}`, "GET", null, appProperties).then(
      (response) => {
        response = JSON.parse(response);
        var license = response.data.licenseDetails;
        appProperties.licenseObj.licenseDetails = license;
        setAppProperties({ ...appProperties, appProperties });
      }
    );

  return (
    <>
      {showChargeBee ? (
        <Drawer
          closable
          closeIcon={<ArrowLeftOutlined style={{ color: "#2196F3" }} />}
          className="chargeebeeDrawer"
          open={true}
          onClose={() => {
            setShowChargeBee(false);
            getLicenseDetails();
          }}
          width={550}
          title={
            <div className="d-flex justify-content-end">
              <img src={OAppSLogo} height={30} />
            </div>
          }
          footer={
            <div className="d-flex justify-content-end">
              <a style={{ textDecoration: "underline", display: "none" }} href="#">
                FaQ
              </a>
              <span style={{ fontSize: 12, color: "#8c8c8c" }}>Subcription powered by chargebee & stripe</span>
            </div>
          }
        >
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="h5 head-chargebee" style={{ color: "#009EFD", fontWeight: 600 }}>
              "Over 500+ businesses have chosen us"
            </div>
            <div className="mt-0 mb-0 m-1 " style={{ color: "#D7D7D7" }}>
              Transparent pricing | 7 days cashback guarantee | Cancel anytime
            </div>
          </div>
          <iframe height={500} width={500} src={checkoutUrl} className="chargebee-iframe"></iframe>
          <div className="m-2 faq-chargebee">
            * Post-trial <strong>monthly charges</strong> : After the 3-day trial period, the indicated amount will be deducted as the monthly
            fee.
            <br />
            <br />*<strong> Wallet Credits</strong> are designated for the following purposes:
            <br />
            <ol className="ms-3 p-0">
              <li className="m-0 p-0">Buying and Renting Phone Numbers</li>
              <li className="m-0 p-0">Inbound and Outbound Messages</li>
              <li className="m-0 p-0">Campaign Maintenance </li>
              <li className="m-0 p-0">10DLC Review Cost. </li>
            </ol>
          </div>
        </Drawer>
      ) : null}
    </>
  );
};
