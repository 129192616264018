import React, { useContext, useState } from "react";
import { Col, Row, Image, Button } from "antd";
import Classes from "../../assets/css/services/leftAuth.module.css";
import HelloSendLogo from "../../assets/images/HelloSendLogo.png";
import HS_bg from "../../assets/images/HS_bg.png";

import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Sidebar from "../../components/main/Sidebar";

import { AppContext } from "./../../context/AppContext";
import useHttp from "../../hooks/useHttp";

function ZohoCRMLandingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchData } = useHttp();
  const [appProperties] = useContext(AppContext);

  let urlParams = new URLSearchParams(window.location.search);
  const serviceOrigin = urlParams.get("serviceOrigin");
  let leftServiceId = urlParams.get("leftServiceId");
  let rightServiceId = urlParams.get("rightServiceId");

  urlParams.delete("topBar");
  useEffect(() => {
    console.log("appProperties?.authCRM >>>>>>>>>>>>>>>>>>>", appProperties?.authCRM);
    if (appProperties?.authCRM) {
      let params = new URLSearchParams(location.search);
      console.log("appProperties?.authCRM  params>>>>>>>>>>>>>>>>>>>", params);
      params.set("sideBar", "true");
      params.set("topBar", "true");
      let updatedSearch = "?" + params.toString();

      <Sidebar />;
      navigate("/channels/textsms" + updatedSearch);
    }
  }, [appProperties?.authCRM]);

  return (
    <>
      <div style={{ height: "100vh" }}>
        <Row>
          <Col span={11}>
            <div className="container" style={{ marginTop: "20vh" }}>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <Image src={HelloSendLogo} preview={false} draggable={false} className="m-auto mb-5" height={82} />
                <h5 className="m-3 mt-5 pt">Sign in to HelloSend</h5>
                <Button
                  type=""
                  style={{ paddingTop: "12px" }}
                  className={Classes["zoho-btn"]}
                  href={
                    appProperties?.leftServiceClientDetails?.authorizeUrl +
                    "?response_type=code&client_id=" +
                    appProperties?.leftServiceClientDetails?.clientId +
                    "&redirect_uri=" +
                    appProperties?.leftServiceClientDetails?.redirectUrl +
                    "&state=" +
                    leftServiceId +
                    ":::" +
                    rightServiceId +
                    "&scope=" +
                    appProperties?.leftServiceClientDetails?.authScopes +
                    "&access_type=offline&serviceOrigin=" +
                    serviceOrigin
                  }
                  target="_blank"
                >
                  Zoho
                </Button>
                <h6>Level Up Your CRM : Integrate SMS into CRM System</h6>
                <div className={Classes["infoPara"]}>
                  Create engagement through SMS offers, <br />
                  premium upgrades that are seamlessly incorporated into your CRM system
                </div>
              </div>
            </div>
          </Col>
          <Col span={13}>
            <ZohoCRMLandingPageMarketingContent />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ZohoCRMLandingPage;

export const ZohoCRMLandingPageMarketingContent = () => {
  return (
    <div className={Classes["zoho-crm-marketing-content"]}>
      <div className={Classes["heads"]}>
        <h3>Turbocharge Your Sales</h3>
        <h5>Convert, Engage, and Win with Conversational Texting!</h5>
        <p>Convert more leads into loyal customers with engaging Conversational Texting that speaks directly to their needs!</p>
      </div>
      <Image preview={false} draggable={false} src={HS_bg} />
    </div>
  );
};
