import { Button, Modal, Form, Input, Radio, Typography, message } from "antd";

import React, { useState, useRef } from "react";
import useAdminHttp from "../hooks/useAdminHttp";

export default function UserActions({ osyncIds, fetchUserData, formFields }) {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const newUserDetailsRef = useRef({});

  const { Title } = Typography;
  const { fetchData } = useAdminHttp();
  const handleAddUser = () => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    const { osyncId, adminUser, name, email, remoteUserId } = newUserDetailsRef.current;
    console.log(osyncId, adminUser, name, email, remoteUserId);
    if (osyncId && adminUser !== undefined && adminUser !== null && name && email && emailRegex.test(email)) {
      const stringifyUser = JSON.stringify(newUserDetailsRef.current);

      try {
        fetchData("customer/users/add?osyncId=" + osyncIds["Osync ID"], "post", stringifyUser)
          .then((res) => {
            if (res?.status === 200) {
              fetchUserData(osyncIds["Osync ID"]);

              setIsModalOpen(false);
              form.resetFields();
              newUserDetailsRef.current = {};
              message.success("User added! They're now part of the team.");
            }
          })
          .catch((error) => {
            message.error(error.message);
          });
      } catch (error) {
        message.error(error);
      }
    } else {
      message.error("Please fill all fields and check your email format");
    }
  };

  const handleModal = () => {
    console.log("User form fields in handle modal", formFields);
    if (formFields) {
      setIsModalOpen(true);
    } else {
      message.error("Error parsing User Schema");
    }
  };

  const handleChange = (event, fields) => {
    newUserDetailsRef.current[fields] = event.target.value;
  };

  const handleOsyncIdChange = (event, fields, osyncIds) => {
    newUserDetailsRef.current[fields] = event.target.value;
    newUserDetailsRef.current["osyncId"] = osyncIds["Osync ID"];
  };

  return (
    <>
      <Button className="actionsButton" onClick={() => handleModal()}>
        Add User
      </Button>
      <Modal
        open={isModalOpen}
        onOk={handleAddUser}
        onCancel={() => {
          setIsModalOpen(false);
          form.resetFields();
        }}
        okText="Add"
        okButtonProps={{ form: "addUserForm", htmlType: "submit" }}
      >
        <Form form={form} layout="vertical" id="addUserForm">
          <Title style={{ padding: "10px 0px" }} level={3}>
            User Details
          </Title>
          {formFields ? (
            Object.keys(formFields).map((fields) => {
              if (formFields[fields] === "string" && fields !== "shardId" && fields !== "osyncUserId" && fields !== "osyncId") {
                if (fields === "remoteUserId") {
                  return (
                    <Form.Item key={fields} label={fields?.toUpperCase()} name={fields}>
                      <Input onChange={(e) => handleChange(e, fields)} />
                    </Form.Item>
                  );
                } else {
                  return (
                    <Form.Item key={fields} label={fields?.toUpperCase()} name={fields} required={true}>
                      <Input onChange={(e) => handleChange(e, fields)} />
                    </Form.Item>
                  );
                }
              } else if (formFields[fields] === "boolean") {
                return (
                  <Form.Item key={fields} label={fields?.toUpperCase()} name={fields} required={true}>
                    <Radio.Group onChange={(e) => handleOsyncIdChange(e, fields, osyncIds)}>
                      <Radio value={1}>True</Radio>
                      <Radio value={0}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                );
              }
              return null;
            })
          ) : (
            <></>
          )}
        </Form>
      </Modal>
    </>
  );
}
