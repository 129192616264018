import React, { useContext, useEffect, useState } from "react";
import useHttp from "../../../hooks/useHttp";

import { Col, Row, Skeleton, Steps, notification } from "antd";
import "../../../assets/css/tendlc.css";
import CreateSecondaryProfile from "./CreateSecondaryProfile";
import { AppContext } from "../../../context/AppContext";
import CreateA2PTrustProduct from "./CreateA2PTrustProduct";
import RegisterBrand from "./RegisterBrand";
import CreateA2pCampaign from "./CreateMsgService";
import RegisterA2PCampaign from "./RegisterA2PCampaign";
import { Chargebee_CHARGE_NAME } from "../../../constants/AppConstants";
import { FundsMonitor, NotEnoughCredits } from "../../utils/FundsMonitor";
import { ExclamationCircleFilled } from "@ant-design/icons";

function TenDlc() {
  const [appProperties, setAppProperties] = useContext(AppContext);
  const { fetchData } = useHttp();

  const [stepsList, setStepsList] = useState();
  const [stepsArr, setStepsArr] = useState([]);
  const [profileId, setProfileId] = useState("");
  const [endUser, setEndUser] = useState([]);
  const [respData, setRespData] = useState();
  const [current, setCurrent] = useState(null);
  const [formFields, setFormFields] = useState();
  const [secondaryProfileStatus, setSecondaryProfileStatus] = useState(null);
  const [campaignResp, setCampaignResp] = useState(null);
  const [msgService, setMsgService] = useState(null);
  const [brandtype, setBrandType] = useState(null);
  const [brandStatus, setBrandStatus] = useState(null);
  const [campaignStatus, setCapaignStatus] = useState(null);
  const [NotEnoughFunds, setNotEnoughFunds] = useState(false);
  const [trustType, setTrustType] = useState(null);
  let rightServiceObj = appProperties.installedApps?.find((obj) => obj?.right?.service_name === "hello_send");
  let integId = rightServiceObj?.integProps?.integId;

  const [chargeBeePlan, setChargeBeePlan] = useState(Chargebee_CHARGE_NAME);
  const [chargeBeeCharge, setChargeBeeCharge] = useState("Messaging-Credits-USD");
  const [alreadyRegisteredFromModal, setAlreadyRegisteredFromModal] = useState("false");
  const [funds, setFunds] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const errorList = (message) => {
    api.info({
      message: `Error`,
      description: message,
      placement: "bottomLeft",
      duration: 0,
      icon: <ExclamationCircleFilled style={{ color: "#F22F46" }} />,
    });
  };

  function handleNoCreditsCancel() {
    setNotEnoughFunds(false);
  }

  useEffect(() => {
    if (integId !== undefined && current === null) {
      fetchData("hellosend/account/" + integId + "/campaign/registration?type=PROFILE", "POST", null, appProperties)
        .then(function (response) {
          response = JSON.parse(response);
          setRespData(response.data);
          setProfileId(response.data.profileId);
          let steps = response.data.steps;
          let stepsArr = steps.map((obj) => {
            return {
              title: obj.name,
            };
          });
          let formFields1 = steps[0]?.profile?.profile;
          let formFields2 = steps[0]?.profile?.business?.attributes;
          let formFields3 = steps[0]?.profile?.primaryPerson?.attributes;
          let formFields4 = steps[0]?.profile?.secondaryPerson?.attributes;
          let formFields5 = steps[0]?.profile?.address;
          let fields = undefined;
          if (formFields1?.friendlyName !== undefined || formFields1?.email !== undefined) {
            fields = {
              friendly_name: formFields1.friendlyName,
              emailId: formFields1.email,
            };
          }
          setFormFields({
            "Name & E-mail": fields,
            "Business Information": formFields2,
            "Authorized Representative #1": formFields3,
            "Authorized Representative #2": formFields4,
            "Physical Business Address": formFields5,
          });
          setStepsList(stepsArr);
          setStepsArr(steps);
          let address = {
            fields: ["customerName", "street", "city", "region", "postalCode", "isoCountry"],
            name: ["Physical Business Address"],
          };
          let Arr = {
            fields: ["friendly_name", "emailId"],
            name: "Name & E-mail",
          };
          setEndUser([Arr, ...response.data.requirements.end_user, address]);
          setTrustType(steps[1]?.product?.company?.attributes);
          setBrandType(steps[2]?.brand?.brandType);
          setBrandStatus(steps[2]?.brand?.status);
          setMsgService(steps[3]?.service);
          setCampaignResp(steps[4]?.campaign);
          setCapaignStatus(steps[4]?.campaign?.campaignStatus);
          if (steps[0]?.product?.failureReason) {
            errorList(steps[0]?.product?.failureReason);
          }
          if (steps[1]?.product?.failureReason) {
            errorList(steps[1]?.product?.failureReason);
          }
          if (steps[2]?.brand?.failureReason) {
            errorList(steps[2]?.brand?.failureReason);
          }
          if (steps[4]?.product?.failureReason) {
            errorList(steps[4]?.service?.failureReason);
          }
          if (steps[5]?.product?.failureReason) {
            errorList(steps[5]?.campaign?.failureReason);
          }
        })
        .catch((error) => {});
    }
  }, []);

  useEffect(() => {
    setSecondaryProfileStatus(stepsArr[0]?.profile?.profile?.status);
    if (stepsArr[4]?.campaign?.campaignStatus !== undefined) {
      setCurrent(4);
    } else if (stepsArr[3]?.service !== undefined) {
      setCurrent(4);
    } else if (stepsArr[2]?.brand?.status !== undefined && !["DRAFT", "PENDING", "REJECTED", "FAILED"].includes(stepsArr[2]?.brand?.status)) {
      setCurrent(3);
    } else if (
      stepsArr[1]?.product?.product?.status !== undefined &&
      stepsArr[1]?.product?.product?.status !== "DRAFT" &&
      stepsArr[1]?.product?.product?.status !== "REJECTED" &&
      stepsArr[1]?.product?.product?.status !== "TWILIO_REJECTED"
    ) {
      setCurrent(2);
    } else if (
      stepsArr[0]?.profile?.profile?.status !== undefined &&
      !["DRAFT", "REJECTED", "TWILIO_REJECTED"].includes(stepsArr[0]?.profile?.profile?.status)
    ) {
      setCurrent(1);
    } else if (stepsArr.length > 0) {
      setCurrent(0);
    }
  }, [stepsArr]);

  useEffect(() => {
    if (appProperties?.licenseObj?.licenseDetails) {
      let { creditsConsumed, creditsPurchased, licenseStatus } = appProperties.licenseObj.licenseDetails;
      let balanceCredits = creditsPurchased - creditsConsumed;
      setFunds(Number.parseFloat(balanceCredits));
      // creditsRef.current = Number.parseFloat(balanceCredits);
    }
  }, [appProperties.licenseObj]);

  return (
    <>
      {contextHolder}
      <Row className="m-3">
        <Col span={4} className="d-flex align-items-center">
          <h4>A2P 10 DLC</h4>
          <span
            onClick={() => {
              window.open("https://help.oapps.xyz/portal/en/kb/articles/10dlc-registration-for-sms-providers#10_DLC_Registration_process");
            }}
            className="ms-2 actionIconsSprite authInfo-icon"
          ></span>
        </Col>
        <Col span={12} offset={7} className="d-flex justify-content-end">
          <FundsMonitor popOver={true} />
        </Col>
      </Row>
      <hr />
      {stepsList !== undefined ? (
        <>
          <Steps
            type="navigation"
            current={current}
            onChange={(e) => {
              if (
                (brandStatus === "FAILED" && (e === 1 || e === 2 || e === 0)) ||
                campaignStatus === "FAILED" ||
                campaignStatus?.includes("REJECTED")
              )
                setCurrent(e);
            }}
            size="small"
            className="tenDlcMenu site-navigation-steps"
            items={stepsList}
          />
          {current === 0 ? (
            <CreateSecondaryProfile
              appProperties={appProperties}
              endUser={endUser}
              profileId={profileId}
              setCurrent={setCurrent}
              respData={respData}
              formFields={formFields}
              setFormFields={setFormFields}
              setSecondaryProfileStatus={setSecondaryProfileStatus}
            />
          ) : current === 1 ? (
            <CreateA2PTrustProduct
              profileId={profileId}
              setCurrent={setCurrent}
              secondaryProfileStatus={secondaryProfileStatus}
              formFields={formFields}
              trustType={trustType}
            />
          ) : current === 2 ? (
            <RegisterBrand
              profileId={profileId}
              brandtype={brandtype}
              brandStatus={brandStatus}
              setCurrent={setCurrent}
              setNotEnoughFunds={setNotEnoughFunds}
              funds={funds}
            />
          ) : current === 3 ? (
            <CreateA2pCampaign profileId={profileId} msgService={msgService} setCurrent={setCurrent} />
          ) : current === 4 ? (
            <RegisterA2PCampaign
              profileId={profileId}
              formFields={formFields["Name & E-mail"]}
              campaignResp={campaignResp}
              funds={funds}
              status={campaignStatus}
              setNotEnoughFunds={setNotEnoughFunds}
            />
          ) : null}
          {NotEnoughFunds ? (
            <NotEnoughCredits
              open={NotEnoughFunds}
              handleNoCreditsCancel={handleNoCreditsCancel}
              chargeBeeCharge={chargeBeeCharge}
              chargeBeePlan={chargeBeePlan}
              alreadyRegistered={alreadyRegisteredFromModal}
              setAlreadyRegistered={setAlreadyRegisteredFromModal}
              appProperties={appProperties}
              setAppProperties={setAppProperties}
              showNoCredits={NotEnoughFunds}
              setShowNoCredits={setNotEnoughFunds}
            />
          ) : null}
        </>
      ) : (
        <Skeleton className="container" size="large" active={true} paragraph={{ rows: 12 }} />
      )}
      <hr />
    </>
  );
}
export default TenDlc;
